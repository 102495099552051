<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>
        {{ $t("edit_house_details") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <form class="fix-for-nepali-label">
          <v-text-field v-model="editedData.houseName"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("house_name") }}
            </template>
          </v-text-field>
          <v-text-field v-model="editedData.houseOwner"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("house_owner_name") }}
            </template>
          </v-text-field>
          <v-text-field v-model="editedData.houseNumber"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("house_no") }}
            </template>
          </v-text-field>
          <v-text-field v-model="editedData.totalRoom"
                        type="Number"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("pax") }}
            </template>
          </v-text-field>
          <v-file-input
            accept="image/png, image/jpeg, image/bmp"
            placeholder="House logo"
            prepend-icon="mdi-camera"
            class="pl-2"
                        dense
                        outlined
            v-model="editedData.image"
          >
            <template v-slot:label>
              {{ $t("avatar") }}
            </template>
          </v-file-input>
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="red darken-1" @click="dialogueClose">{{
        $t("cancel")
      }}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "EditHouseForm",
  props: ["success", "editData"],
  computed: {
    packageNameErrors() {
      const errors = [];
      if (!this.$v.editedData.packageName.$dirty) return errors;
      !this.$v.editedData.packageName.required &&
        errors.push("Package Name is required.");
      return errors;
    },
    packageTypeErrors() {
      const errors = [];
      if (!this.$v.editedData.packageType.$dirty) return errors;
      !this.$v.editedData.packageType.required &&
        errors.push("Package Type is required.");
      return errors;
    },
  },
  data() {
    return {
      returnData: [],
      show: false,
      updated: false,
      editedData: {
        houseName: "",
        houseOwner: "",
        houseNumber: "",
        totalRoom: "",
        image: "",
      },
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  validations: {
    editedData: {
      houseName: { required },
      houseOwner: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      const response = await axios.get(
        "Hotel/GetHomestayHouseByIDAsync/" + this.editData.homestayHouseID
      );
      console.log(response.data);
      this.editedData = response.data;
      this.updated = false;
    },
    submit() {
      var form = new FormData();
      {
        form.append("Image", this.editedData.image);
        form.append("homestayHouseID", this.editedData.homestayHouseID);
        form.append("HouseName", this.editedData.houseName);
        form.append("HouseNumber", this.editedData.houseNumber);
        form.append("HouseOwner", this.editedData.houseOwner);
        form.append("TotalRoom", this.editedData.totalRoom);
      }
      //let param = {
      //    homestayHouseID: this.editedData.homestayHouseID,
      //    HouseName: this.editedData.houseName,
      //    HouseNumber: this.editedData.houseNumber,
      //    HouseOwner: this.editedData.houseOwner,
      //    TotalRoom: this.editedData.totalRoom,
      //    //Image: this.editedData.image,
      //};
      axios
        .post("Hotel/UpdateHomestayHouseAsync", form)
        .then((response) => {
          if (response.data.success) {
            this.updated = true;
            this.editedData = {};
            this.servicesData.data = response.data;
            this.servicesData.message = "House Updated Successfully";
            this.servicesData.color = "success";
            this.dialogueClose();
          }
        })
        .catch((response) => {
          this.servicesData.data = response.data;
          this.servicesData.message =
            "Error Adding House, Please Contact Admin";
          this.servicesData.color = "error";
          this.dialogueClose();
        });
    },
    clear() {
      this.$v.$reset();
      this.editedData.Fare = "";
    },
  },
};
</script>

<style scoped></style>
